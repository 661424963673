import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./configs/Routes";
import { basename } from "./config.json";
//import AuthApi from "./services/AuthApi";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
//import "./assets/fonts/msyh.ttc";

function App() {
  //const [auth, setAuth] = React.useState(false);
  return (
    // <AuthApi.Provider value={[auth, setAuth]}>
    <div className="vh-100">
      <ToastContainer />
      <Router basename={basename}>
        <Routes />
      </Router>
    </div>
    // </AuthApi.Provider>
  );
}

export default App;
